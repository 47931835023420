@font-face {
  font-family: "Ethnocentric";
  src: local("Ethnocentric"),
    url(./fonts/ethnocentric-rg.otf) format("opentype");
}

.bb1 {
  border: 4px solid black;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif;
}

.header {
  position: fixed;
  width: 100vw;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.logoHeaderContainer {
  display: flex;
  padding-left: 1vw;
  cursor: pointer;
}

.headerLogo {
  position: relative;
  height: 7rem;
  top: 0.55rem;
}

.textHeaderLogo {
  align-self: center;
  /* display: none; */
  /* @media screen and (min-width: 400px) {
    display: block;
  } */
}

.navHeaderContainer {
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    margin-right: 2vw;
  }
  @media screen and (min-width: 1170px) {
    margin-right: 3vw;
  }
  @media screen and (min-width: 1440px) {
    margin-right: 4vw;
  }
}

.headerNav {
  display: flex;
  margin-right: 2vw;
  column-gap: 3vw;
  @media screen and (min-width: 1170px) {
    margin-right: 3vw;
    column-gap: 4vw;
  }
  @media screen and (min-width: 1440px) {
    margin-right: 4vw;
    column-gap: 5vw;
  }
}

.headerMenu {
  /* padding-right: 1vw; */
  color: white;
  right: 0;
  position: absolute;
  /* display: flex; */
  /* align-items: center; */
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.headerMenuOpen {
  display: flex;
  flex-direction: column;
}

.headerMenuLinks {
  /* text-decoration: none; */
  /* text-transform: uppercase; */
  /* font-size: 0.9rem; */
  /* font-weight: 600; */
  /* letter-spacing: 0.05em; */
  /* --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity)); */
  display: flex;
  flex-direction: column;
  width: 19rem;
  align-items: center;
  row-gap: 3rem;
  padding-top: 2vh;
  padding-bottom: 4vh;

  /* transition: all 0.2s ease-in-out; */
}

.headerMenuIconOpen {
  /* padding-top: 0.75rem; */
  /* justify-content: center; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding-right: 1rem;
  color: black;
  font-size: 2rem;
  @media screen and (min-width: 800px) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.headerMenuIconClose {
  padding-top: 1rem;
  padding-right: 1rem;
  font-size: 2.5rem;
  align-self: end;
}

.headerLanguage {
  display: flex;
  align-items: center;
  /* height: 100%; */
  letter-spacing: 0.3em;
  margin-top: 0.15rem;
}

.headerLanguage2 {
  display: flex;
  justify-content: end;
}

.flag {
  font-size: 1.2em;
  margin-left: 0.25rem;
  @media screen and (min-width: 1170px) {
    margin-left: 0.5rem;
  }
}

.secondFlag {
  position: absolute;
  /* margin-left: 1.625rem; */
}

.websiteContainer {
  /* max-width: 100vw; */
}

.fullSizeSection {
  height: 100vh;
  width: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.introContainer {
  height: fit-content;
  min-height: 90vh;
  padding-bottom: 10vh;
}

.introSection {
  display: flex;
  flex-direction: column;
  /* padding-top: 9rem; */
  /* height: 100vh; */
  /* width: 99vw; */
  /* display: flex; */
  padding-right: 5vw;
  padding-left: 5vw;
  padding-top: 7rem;
  @media screen and (min-width: 600px) {
    padding-right: 10vw;
    padding-left: 10vw;
    padding-top: 8rem;
  }
}

.logoIntro {
  /* height: 18rem; */
  align-self: center;
  height: 12rem;
  padding-bottom: 1rem;
  @media screen and (min-width: 600px) {
    padding-bottom: 2rem;
    height: 16rem;
  }
}

.mainTextsIntro {
  /* display: grid;
  width: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 1rem; */
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  /* align-items: center; */
  align-self: center;
}

.mainDescr0 {
  /* grid-column-start: 3;
  grid-column-end: 13; */

  /* display: flex; */
  /* grid-column: span 3 / span 12; */
  /* padding-left: 15vw; */
}

.firstLetterMainDescr {
  font-size: 4.5rem;
  @media screen and (min-width: 600px) {
    font-size: 7rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 8rem;
  }
  @media screen and (min-width: 960px) {
    font-size: 10rem;
  }
  @media screen and (min-width: 1170px) {
    font-size: 12rem;
  }
  /* margin-right: -1.8rem;
  margin-left: -1.8rem; */
}

.secondLetterMainDescr {
  margin-left: -0.5rem;
  @media screen and (min-width: 600px) {
    margin-left: -1.2rem;
  }
  @media screen and (min-width: 800px) {
    margin-left: -1.6rem;
  }
  @media screen and (min-width: 960px) {
    margin-left: -2.4rem;
  }
  @media screen and (min-width: 1170px) {
    margin-left: -3rem;
  }
}

.mainDescr1 {
  /* grid-column-start: 4;
  grid-column-end: 13; */
  font-size: 30px;
  padding-left: 10vw;
}

.mainDescr2 {
  width: fit-content;
  margin: auto;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  margin-top: 2rem;
  @media screen and (min-width: 600px) {
    margin-top: 4rem;
  }
}

.textMainDescr2 {
  /* width: 70vw; */
  text-align: center;
}

.servicesSection {
  /* height: 100vh; */
  /* padding-top: 6rem; */
  padding-bottom: 15vh;
}

.servicesTitle {
  position: sticky;
  /* width: 100vw; */
  margin-bottom: 60vh;
  top: 4rem;
  text-transform: capitalize;
  z-index: 40;
  /* padding-left: 7.5rem; */
  /* padding-left: 15vw; */
  /* align-self: flex-end; */
  /* justify-content: center; */
  text-align: end;
  padding-right: 2rem;
  padding-left: 2rem;
  @media screen and (min-width: 500px) {
    text-align: start;
    padding-right: 0;
    padding-left: 7.5rem;
  }
}

.servicesContainer {
  margin-top: -60vh;
  margin-left: 5vw;
  margin-right: 5vw;
  @media screen and (min-width: 800px) {
    margin-left: 10vw;
    margin-80ght: 10vw;
  }
  @media screen and (min-width: 960px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }
  @media screen and (min-width: 1170px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.serviceContentRightContainer {
  min-height: 20rem;
  margin-top: 4rem;
}

.serviceContentLeftContainer {
  margin-top: 4rem;
  min-height: 20rem;
  /* flex-direction: column;
  flex-direction: row; */
  @media screen and (min-width: 1170px) {
    display: flex;
  }
}

.titleBoxService {
  text-align: center;
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  @media screen and (max-width: 599px) {
    font-size: 20px !important;
  }
  @media screen and (min-width: 600px) {
    padding-bottom: 2.2rem;
  }
  @media screen and (min-width: 1170px) {
    padding-bottom: 1.5rem;
    padding-top: 0;
  }
}

.textBoxService {
  /* display: flex; */
  /* align-items: center; */
  /* margin-left: 3rem; */
  /* margin-right: 3rem; */
  text-align: center;
  line-height: 110%;
  font-size: 1rem;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 1rem;
  @media screen and (min-width: 600px) {
    text-align: justify;
    line-height: 150%;
    font-size: 1.15rem;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: 1170px) {
    font-size: 1.11rem;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
}

.imageServiceContentRight {
  position: absolute;
  height: 20rem;
  width: 45%;
  justify-content: end;
  padding-right: 1vw;
  display: none;
  @media screen and (min-width: 1170px) {
    display: flex;
  }
}

.imageServiceContentSmall {
  /* width: 45%; */
  /* padding-right: 1vw; */
  /* margin-top: 4rem; */
  justify-content: center;
  display: flex;
  height: 14rem;
  @media screen and (min-width: 800px) {
    height: 16rem;
  }
  @media screen and (min-width: 960px) {
    height: 18rem;
  }
  @media screen and (min-width: 1170px) {
    display: none;
  }
}

.imageServiceContentLeft {
  position: absolute;
  height: 20rem;
  width: 45%;
  justify-content: start;
  right: 0;
  padding-left: 1vw;
  display: none;
  @media screen and (min-width: 1170px) {
    display: flex;
  }
}

.imageServices {
  /* height: 17rem;
  @media screen and (min-width: 800px) {
    height: 18rem;
  }
  @media screen and (min-width: 960px) {
    height: 19rem;
  }
  @media screen and (min-width: 1170px) {
    height: 20rem;
  } */
}

.OAContainer {
  min-height: 100vh;
}

.OASection {
  min-height: 90vh;
  padding-bottom: 10vh;
  /* height: fit-content; */
}

.boxesOA {
  /* align-items: stretch; */
  /* display: flex; */
  margin-left: 8vw;
  margin-right: 8vw;
  padding-top: 5rem;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  align-items: center;
  @media screen and (min-width: 1170px) {
    flex-direction: row;
    align-items: stretch;
  }
}

.boxOA {
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
  border-radius: 0.75rem;
  align-items: center;
  row-gap: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 95%;
  @media screen and (min-width: 800px) {
    padding-top: 2rem;
    padding-bottom: 3rem;
    width: 85%;
  }
  @media screen and (min-width: 800px) {
    width: 75%;
  }
  @media screen and (min-width: 960px) {
    width: 65%;
  }
  @media screen and (min-width: 1170px) {
    width: 40%;
  }
}

.OAIgm {
  height: 13rem;
  @media screen and (min-width: 800px) {
    height: 14.5rem;
  }
  @media screen and (min-width: 1440px) {
    height: 16rem;
  }
}

.OATitle {
  padding-top: 6rem;
  text-transform: capitalize;
  text-align: end;
  margin-right: 2rem;
  @media screen and (min-width: 600px) {
    text-align: start;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  @media screen and (min-width: 960px) {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
}

.titleBoxOA {
  text-transform: uppercase;
  text-align: center;
  /* padding-left: 1rem;
  padding-right: 1rem; */
}

.textBoxOA {
  font-size: 1.03rem;
  text-align: center;
  line-height: 135%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  @media screen and (min-width: 600px) {
    font-size: 1.11rem;
    text-align: justify;
    line-height: 150%;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.aboutPageContainer {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 10vh;
  row-gap: 4vh;
  @media screen and (min-width: 1440px) {
    row-gap: 2vh;
  }
}

.aboutTitle {
  padding-top: 6rem;
  padding-bottom: 1rem;
  /* margin-left: 8vw; */
  /* margin-right: 8vw; */
  margin-left: 11vw;
  margin-right: 11vw;
  /* align-self: flex-end; */
  text-transform: capitalize;
  text-align: end;
  @media screen and (min-width: 600px) {
    text-align: start;
  }
}

.aboutDescr {
  text-align: center;
  margin-left: 9vw;
  margin-right: 9vw;
  font-size: 1.08rem;
  line-height: 150%;
}

.aboutContainer {
  display: flex;
  /* flex-direction: column; */
  column-gap: 2rem;
  margin-left: 5vw;
  margin-right: 5vw;
  @media screen and (min-width: 600px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media screen and (min-width: 800px) {
    margin-left: 5vw;
    margin-right: 5vw;
    flex-direction: row;
  }
  @media screen and (min-width: 1170px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

.aboutBoxes {
}

.aboutBox {
  /* margin-right: 8vw; */
  height: 100%;
  width: 100%;
  /* margin-left: 10vw; */
  align-self: center;
  border: 1px solid rgb(71 85 105);
  border-radius: 1rem;
  row-gap: 0.4rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  /* --tw-border-opacity: 1; */
  /* border-color: rgb(71 85 105 / var(--tw-border-opacity)); */
}

.aboutBoxCC {
  margin-left: 1vw;
  margin-right: 0;
  @media screen and (min-width: 1170px) {
    margin-right: 2vw;
  }
  @media screen and (min-width: 1440px) {
    margin-right: 4vw;
  }
}

.aboutBoxFM {
  margin-right: 1vw;
  margin-left: 0;
  @media screen and (min-width: 1170px) {
    margin-left: 2vw;
  }
  @media screen and (min-width: 1440px) {
    margin-left: 4vw;
  }
}

.aboutImg {
  /* position: relative; */
  align-self: center;
  height: 42vh;
  @media screen and (min-width: 960px) {
    height: 52vh;
  }

  display: none;
  @media screen and (min-width: 800px) {
    display: block;
  }
}

.aboutImgSmall {
  /* position: relative; */
  align-self: center;
  height: 42vh;
  @media screen and (min-width: 960px) {
    height: 52vh;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
}

.aboutQuote {
  align-self: center;
  font-weight: 700;
  display: none;
  @media screen and (min-width: 700px) {
    display: block;
  }
  font-size: 2.2rem;
  @media screen and (min-width: 800px) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 960px) {
    font-size: 3rem;
  }
}

.aboutBoxText {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 1.3;
  row-gap: 1.5rem;
  font-size: 1rem;
  @media screen and (min-width: 600px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-align: justify;
    line-height: 1.4;
    /* row-gap: 1.5rem; */
    font-size: 1.05rem;
  }
  @media screen and (min-width: 700px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 960px) {
    row-gap: 1rem;
    line-height: 1.5;
    /* font-size: 1.11rem; */
  }
  @media screen and (min-width: 1170px) {
    line-height: 1.75;
    /* font-size: 1.11rem; */
  }
}

.aboutBoxTitle {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
  /* text-justify: center; */
  padding-bottom: 1rem;
  /* padding-top: 1rem; */
}

.ccTitle {
  @media screen and (min-width: 960px) {
    justify-content: start;
    padding-left: 3rem;
  }
}

.aboutBoxDescr {
  display: flex;
  column-gap: 1rem;
  @media screen and (min-width: 960px) {
    column-gap: 1.5rem;
  }
}

.aboutFM {
}

.fmTitle {
  @media screen and (min-width: 960px) {
    justify-content: end;
    padding-right: 3rem;
  }
}

.contactContainer {
  /* height: 93vh; */
  height: 100vh;
  margin: auto;
  /* align-items: center; */
  justify-content: center;
  /* column-gap: 2rem; */
  /* padding-top: 16rem; */
  /* padding-top: -2rem; */
  width: 75vw;
  @media screen and (min-width: 1170px) {
    width: 65vw;
  }
  @media screen and (min-width: 1440px) {
    width: 55vw;
  }
}

.contactBox {
  /* height: 100%; */
  /* width: 100%; */
}

.titleContact {
  text-align: center;
  padding-top: 5rem;
  @media screen and (min-width: 600px) {
    padding-top: 0;
  }
}

.contactForm {
  /* text-align: center; */
  padding-top: 3rem;
  row-gap: 1.5rem;
  @media screen and (min-width: 600px) {
    padding-top: 3.5rem;
    row-gap: 2.5rem;
  }
}

.inputContact {
  border-width: 0;
  outline: 2px solid transparent;
  border-bottom-width: 1px;
  font-size: 100%;
  /* border-bottom-color: rgb(14 17 18 / var(--tw-border-opacity)); */
  /* --tw-border-opacity: 1; */
  /* background-color: transparent; */
  padding-left: 0.75rem;
}

.inputContact::placeholder {
  color: rgb(34 163 69 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.nameAndTextContactInputs {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  @media screen and (min-width: 600px) {
    row-gap: 2rem;
  }
  @media screen and (min-width: 800px) {
    flex-direction: row;
    column-gap: 4rem;
  }
}

.contactName {
  width: 100%;
  height: 60px;
}

.contactEmail {
  width: 100%;
  height: 60px;
}

.contactMessage {
  height: 5rem;
  width: 100%;
  @media screen and (max-width: 800px) {
    padding-top: 1rem;
  }
  @media screen and (min-width: 800px) {
    width: auto;
    padding-top: auto;
  }
}

.sendContactBtn {
  display: flex;
  height: 66px;
  width: 160px;
  margin: auto;
  /* margin-left: 30vh;
  margin-right: 30vh; */
  /* padding-top: 18px; */
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  justify-content: center;
  /* font-size: 1rem; */
  /* line-height: 1.5rem; */
  text-transform: uppercase;
  /* font-weight: 600; */
  --tw-bg-opacity: 1;
  background-color: rgb(14 17 18 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  margin-top: 4.2rem;
  @media screen and (min-width: 600px) {
    margin-top: 5.5rem;
  }
}

.footerContainer {
  margin-top: -7vh;
  /* border-radius: 0.125rem; */
  display: block;
}

.upperLineFooter {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-top-color: rgb(148 163 184 / var(--tw-border-opacity));
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 0.5rem;
}

.footerTexts {
  display: flex;
  height: 4.5rem;
  /* width: 100%; */
  /* margin: auto; */
  align-self: center;
  /* --tw-text-opacity: 1; */
  /* color: rgb(148 163 184 / var(--tw-text-opacity)); */
  /* margin-left: 10%;
  margin-right: 10%; */
  row-gap: 0.2rem;
  justify-content: center;
  font-size: 0.8rem;
  flex-direction: column;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  @media screen and (min-width: 600px) {
    align-items: center;
    justify-content: space-evenly;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @media screen and (min-width: 700px) {
    row-gap: 0;
    font-size: 0.9rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1170px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.devCredits {
  display: none;
  @media screen and (min-width: 1170px) {
    display: block;
  }
}

.textColorWhite {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.footerTextsColor {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.sirenContent {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  column-gap: 1rem;
  /* align-self: end; */
  @media screen and (max-width: 599px) {
    line-height: 140%;
  }
}

.boxShadowG {
  box-shadow: rgb(5, 5, 5, 0.85) 0px 20px 20px 0px;
  @media screen and (min-width: 1170px) {
    box-shadow: rgb(5, 5, 5, 0.85) -10px 10px 10px 0px;
  }
}

.boxShadowD {
  box-shadow: rgb(5, 5, 5, 0.85) 0px 20px 20px 0px;
  @media screen and (min-width: 1170px) {
    box-shadow: rgb(5, 5, 5, 0.85) 10px 10px 10px 0px;
  }
}

.font1 {
  font-family: "Ethnocentric", sans-serif;
  text-transform: capitalize;
  /* line-height: 120%; */
  font-size: 25px;
  @media screen and (min-width: 400px) {
    font-size: 28px;
  }
  @media screen and (min-width: 500px) {
    font-size: 35px;
  }
  @media screen and (min-width: 1170px) {
    font-size: 40px;
  }
}

.fontEthnocentric {
  font-family: "Ethnocentric", sans-serif;
}

.font2 {
  font-family: "Orbitron", sans-serif;
  font-weight: 800;
  line-height: 1.75rem;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  @media screen and (min-width: 1440px) {
    font-size: 1.25rem;
    letter-spacing: 0.1em;
  }
}

.font3 {
  font-weight: 800;
  font-size: 1.25rem;
}

.fontAldrich {
  font-family: "Aldrich", sans-serif;
}

.font4 {
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.05em;
  /* font-size: 28px; */
  font-size: 35px;
  @media screen and (min-width: 600px) {
    font-size: 45px;
  }
  @media screen and (min-width: 800px) {
    font-size: 50px;
  }
  @media screen and (min-width: 800px) {
    font-size: 55px;
  }
  @media screen and (min-width: 960px) {
    font-size: 60px;
  }
  @media screen and (min-width: 1170px) {
    font-size: 70px;
  }
}

.fontOrbitron {
  font-family: "Orbitron", sans-serif;
}

.font5 {
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.05em;
  font-size: 28px;
  @media screen and (min-width: 800px) {
    font-size: 33px;
  }
  @media screen and (min-width: 960px) {
    font-size: 40px;
  }
  @media screen and (min-width: 1170px) {
    font-size: 49px;
  }
}

.fontRationale {
  font-family: "Rationale", sans-serif;
}

.font6 {
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.05em;
  font-size: 24px;
  @media screen and (min-width: 800px) {
    font-size: 26px;
  }
  @media screen and (min-width: 800px) {
    font-size: 28px;
  }
  @media screen and (min-width: 960px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1170px) {
    font-size: 32px;
  }
}

.fontAudiowide {
  font-family: "Audiowide", sans-serif;
}

.font7 {
  font-weight: 700;
  font-size: 23px;
  @media screen and (min-width: 800px) {
    font-size: 24px;
  }
  @media screen and (min-width: 960px) {
    font-size: 25px;
  }
  @media screen and (min-width: 1170px) {
    font-size: 26px;
  }
}

.font8 {
  font-size: 1.8rem;
  @media screen and (min-width: 800px) {
    font-size: 1.6rem;
  }
  @media screen and (min-width: 960px) {
    font-size: 1.8rem;
  }
  @media screen and (min-width: 1170px) {
    font-size: 2rem;
  }
}

.font9 {
  font-size: 1.875rem;
  font-weight: 600;
}

.greenText {
  color: rgb(7, 152, 51);
}

.pointer {
  cursor: pointer;
}

.blurEffectBg {
  /* transition: all 0.5s ease-in-out; */
  background: rgb(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.blurBoxBlackEffect {
  /* transition: all 0.5s ease-in-out; */
  background: rgb(0, 0, 0, 0.25);
  /* backdrop-filter: inherit; */
  /* backdrop-filter: blur(0.5px); */
  border-radius: 1.5rem;
}

.blurBgBlackEffect {
  /* height: 100%; */
  background: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  /* z-index: 60; */
}

.blurBgBlackEffect2 {
  /* transition: all 0.5s ease-in-out; */
  background: rgb(0, 0, 0, 0.75);
  backdrop-filter: blur(6px);
}

.bgWhiteTransparent {
  height: 100%;
  /* width: 100%; */
  background: rgb(255, 255, 255, 0.25);
  backdrop-filter: blur(6px);
}

.blurOA {
}

.headerMenuLinksBg {
  background: rgb(0, 0, 0, 1);
}

.introBg {
  /* position: absolute; */
  /* height: 100%; */
  background-image: url("img/bg/intro.jpg");
  /* background-size: 100%; */
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.servicesBg {
  background: repeating-radial-gradient(
      circle at 0 0,
      rgba(255, 255, 255, 0.1) 40px,
      rgba(153, 153, 153, 0.1) 80px,
      rgba(102, 102, 102, 0.1) 120px,
      rgba(210, 210, 210, 0.1) 160px,
      rgba(136, 136, 136, 0.1) 200px,
      rgba(90, 90, 90, 0.1) 240px,
      rgba(167, 167, 167, 0.1) 280px,
      rgba(192, 192, 192, 0.1) 300px
    ),
    repeating-radial-gradient(
      circle at 100% 100%,
      rgba(255, 255, 255, 0.1) 30px,
      rgba(153, 153, 153, 0.1) 60px,
      rgba(102, 102, 102, 0.1) 90px,
      rgba(210, 210, 210, 0.1) 120px,
      rgba(136, 136, 136, 0.1) 150px,
      rgba(90, 90, 90, 0.1) 180px,
      rgba(167, 167, 167, 0.1) 210px,
      rgba(192, 192, 192, 0.1) 230px
    ),
    repeating-linear-gradient(
      190deg,
      rgba(255, 255, 255, 0.1) 30px,
      rgba(153, 153, 153, 0.1) 60px,
      rgba(102, 102, 102, 0.1) 90px,
      rgba(210, 210, 210, 0.1) 120px,
      rgba(136, 136, 136, 0.1) 150px,
      rgba(90, 90, 90, 0.1) 180px,
      rgba(167, 167, 167, 0.1) 210px,
      rgba(192, 192, 192, 0.1) 230px
    ),
    repeating-linear-gradient(
      -190deg,
      rgba(255, 255, 255, 0.1) 30px,
      rgba(153, 153, 153, 0.1) 60px,
      rgba(102, 102, 102, 0.1) 90px,
      rgba(210, 210, 210, 0.1) 120px,
      rgba(136, 136, 136, 0.1) 150px,
      rgba(90, 90, 90, 0.1) 180px,
      rgba(167, 167, 167, 0.1) 210px,
      rgba(192, 192, 192, 0.1) 230px
    );
  background-attachment: fixed;
}

.OABg {
  background-image: url("img/bg/OAbg2.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  /* @apply h-[100vh]; */
  /* z-index: -10; */
}
